import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SessionApiService } from './session-api.service';
import { SessionGuard } from './session.guard';
import { SessionResolver } from './session.resolver';

@NgModule({
  imports: [RouterModule],
  providers: [SessionApiService, SessionGuard, SessionResolver],
})
export class SessionDataAccessModule {}
